<template>
  <el-dialog title="出厂信息" :visible.sync="dialogTableVisible" :close-on-click-modal="false">
    <el-table :data="data" v-loading="loading" ref="singleTable" highlight-current-row @current-change="handleCurrentChange" height="250">
      <el-table-column property="bwCode" label="标物编号"></el-table-column>
      <el-table-column property="bwName" label="标物名称"></el-table-column>
      <el-table-column property="zfmc" label="组分名称"></el-table-column>
      <el-table-column property="InspectionEquipment" label="质检设备名称"></el-table-column>
    </el-table>
    <div style="width: 70vh;margin-top: 10px;">
        <div style="display: flex;flex-wrap:wrap;;width: 50vw;">
            <div v-for="(item, index) in imgList" :key="item.id">
                <el-image
                style="width: 200px;margin-left: 5px;"
                :src="item.url"
                :zoom-rate="1.2"
                :max-scale="5"
                :min-scale="0.2"
                title="点击查看原图"
                :preview-src-list="viewUrl"
                :initial-index="index"
                @click="viewPicture(index)"
                fit="cover" />
            </div>
        </div>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogTableVisible = false" size="mini">关 闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
  import {
    post,
    get,
    getextra,
    downloadFile
  } from '@/utils/request';
  export default {
    name: 'quality-list',
    data() {
      return {
        dialogTableVisible: false,
        loading: true,
        data: null,
        skuBasic: {
          skuName: null
        },
        orderNo: null,
        currentRow: null,
        orderSkuId: 0,
        batchNumber:'',
        // 图片相关变量
        // imgStr:'http://47.104.148.199:28207',
        imgStr:'',
        index:0,
        viewUrl:[],
        imgList:[]
      };
    },
    methods: {
      initData() {
        get('api/cert/getQualityInfoList?batchNumber=' + this.batchNumber).then((res) => {
          if(res.data.data.dataList!=null){
              this.imgList=[];
              this.data = res.data.data.dataList;
              res.data.data.dataList.forEach((i) => {
                // 是否需要展示质检设备（0:否，1：是）
                if(i.IsInspectionEquipment=='1'){
                    if(i.InspectionEquipment_Img!=null){
                      this.viewUrl.push(this.imgStr+i.InspectionEquipment_Img);
                      this.imgList.push({id:i.zfmc,url:this.imgStr+i.InspectionEquipment_Img});
                    }
                }
              })
          }
          this.loading=false
        })
        // this.imgList.forEach((i) => {
        //     this.viewUrl.push(i.url)
        // })
      },
      handleCurrentChange(val) {
        this.currentRow = val;
      },
      viewPicture(i){
        index = Number(i)
      }
    },
  };
</script>

<style scoped>
::v-deep .el-image-viewer__wrapper{
  padding: 100px 0 100px 0;

}

::v-deep .el-image-viewer__img{
  width: 50% !important;
  height: auto !important;
}
</style>