<template>
  <div class="right">
    <span class="spen">我的订单</span>
    <div class="menu">
      <div class="daohang">
        <ul class="ul">
          <router-link :to="{ name: 'Dingdan' }" tag="li">全部订单</router-link>
          <router-link :to="{ name: 'Fukuan' }" tag="li">待付款</router-link>
          <router-link :to="{ name: 'Fahuo' }" tag="li">待发货</router-link>
          <router-link :to="{ name: 'Shouhuo' }" tag="li">待收货</router-link>
          <router-link :to="{ name: 'Ping' }" tag="li">待评价</router-link>
          <router-link :to="{ name: 'Huishou' }" tag="li">订单回收站</router-link>
        </ul>
      </div>
      <Loading v-if="isLoading1" />
      <div class="search">
        <input type="text" v-model="mes" @keyup.enter.prevent="search" placeholder="输入商品订单号或商品名称" />
        <div class="fangdajing" @click="search">
          <img src="../../../assets/order/fangdajing.png" alt="" />
        </div>
      </div>
    </div>

    <Loading v-if="isLoading" />
    <div v-else>
      <div class="tishi" v-if="order_list == ''">{{ tishi }}</div>
      <div v-for="(item, index) in order_list" :key="index">
        <div class="top">
          <div class="biaoti">{{ item.createTime }}</div>
          <div class="biaoti mar">
            订单号：<span class="danhao">{{ item.orderNo }}</span>
          </div>
          <div class="biaoti mar dianpu" @click="dianpu(item)">
            店铺：{{ item.storeName }}
          </div>
          <!-- <div class="biaoti mar kefu">
            <span>客服</span><Uimage src="../../../assets/order/kefu.png" />
          </div> -->
        </div>
        <div class="bottom" v-for="(res, k) in item.orderSkuList" :key="k">
          <div class="pic">
            <Uimage :src="res.url" alt="" />
          </div>
          <div class="title1">
            <div class="title">
              <div class="miaoshu" @click="xiangqing(item.storeId, res.spuId)">
                {{ res.spuName }}
              </div>
              <div class="shuliang">X{{ res.skuCount }}</div>
            </div>
            <!-- 商品属性 -->
            <div class="shuxing" v-for="(q, c) in res.saleAttrCode" :key="c">
              <div v-if="q.attrName">{{ q.attrName }}：</div>
              <div v-if="q.attrValue" v-html="q.attrValue"></div>
            </div>
          </div>
          <div class="tousu">
            <span v-if="res.isTrue == 'true'">投诉卖家</span>
          </div>
          <div class="tousu">
            <span v-if="res.isTrue == 'true'">{{item.companyName}}</span>
          </div>
          <div class="price">
            <div class="jiage" v-if="item.orderSource == 4">
              {{ res.useScore }}
            </div>
            <div class="jiage" v-else>
              ￥{{ Number(res.skuRealPrice * res.skuCount).toFixed(2) }}
            </div>
            <!-- <div class="yunfei">
              (含运费:￥{{ Number(res.freight).toFixed(2) }})
            </div> -->
            <div class="fangshi" v-if="item.orderSource == 4">积分兑换</div>
            <div class="fangshi" v-else>在线支付</div>
          </div>
          <div class="zhuangtai">
            <div v-if="item.orderStatus == 0 && res.isTrue == 'true'&&item.orderSource!=4">
              等待买家付款
            </div>
            <div v-if="item.orderStatus == 0 && res.isTrue == 'true'&&item.orderSource==4">
              等待买家兑换
            </div>
            <div v-if="item.orderStatus == 1 && res.isTrue == 'true'">
              等待卖家发货
            </div>
            <div v-if="item.orderStatus == 2 && res.isTrue == 'true'">
              待收货
            </div>
            <div v-if="item.applyStatus == 3">调价申请不通过</div>
            <!--评价状态-->
            <div v-if="
                (item.orderStatus == 3 || item.orderStatus == 4) &&
                  res.isTrue == 'true'
              ">
              <div v-if="res.commentStatus == 0">
                待评价
              </div>
              <div v-if="res.commentStatus == 1">
                买家已评价
              </div>
              <div v-if="res.commentStatus == 2">
                卖家已评价
              </div>
              <div v-if="res.commentStatus == 3">
                双方已评
              </div>
            </div>
            <div v-if="item.orderStatus == 4 && res.isTrue == 'true'">
              交易成功
            </div>
            <div v-if="item.orderStatus == 5 && res.isTrue == 'true'">
              退款中
            </div>
            <div v-if="item.orderStatus == 6 && res.isTrue == 'true'">
              已退款
            </div>
            <div v-if="item.orderStatus == 7 && res.isTrue == 'true'">
              已删除
            </div>
            <div v-if="item.orderStatus == 10 && res.isTrue == 'true'">
              已取消
            </div>
            <div v-if="item.orderStatus == 20 && res.isTrue == 'true'">
              超时交易关闭
            </div>
            <div class="xiangqing" @click="ding_detail(res)" v-if="res.isTrue == 'true'">
              订单详情
            </div>
            <div class="wuliu" v-if="
                (item.orderStatus == 2 ||
                  item.orderStatus == 3 ||
                  item.orderStatus == 4) &&
                  res.isTrue == 'true'
              " @click="wuliu(item)">
              查看物流
            </div>
          </div>
          <div class="zhuangtai">
            <!--订单确认状态-->
            <div v-if="item.confirmStatus == 0 && res.isTrue == 'true'">
              待确认
            </div>
            <div v-if="item.confirmStatus == 1 && res.isTrue == 'true'">
              已确认
            </div>
          </div>
          <div class="pay">
            <div class="liji" v-if="item.orderStatus == 10&& item.orderSource != 4" @click="buy(item, res)">
              重新购买
            </div>
            <!-- <div class="liji" v-if="item.orderStatus == 4" @click="buy(item, res)"> -->
            <div class="liji" v-if="item.applyStatus == 2&& item.orderSource != 4" @click="buy(item, res)">
              再次购买
            </div>
            <!-- v-if="item.orderStatus == 4"  -->
            <div class="liji1" v-if="item.orderStatus == 3 && res.commentStatus == 0" @click="pingjia(res)">
              立即评价
            </div>
            <div v-if="item.payBasic != null">
              <div class="liji" v-if="
                  item.orderStatus == 1 &&
                    item.payBasic.confirmStatus == '0' &&
                    item.payBasic.paymentModel == '对公转账'&&res.isTrue == 'true'
                " @click="updateVoucher(item)">
                修改上传凭证
              </div>
            </div>
            <div v-if="item.payBasic != null">
              <div class="liji" v-if="
                  item.orderStatus != 0 &&
                    item.payBasic.confirmStatus == '1' &&
                    item.payBasic.paymentModel == '对公转账'
                " @click="lookVoucher(item)">
                查看上传凭证
              </div>
            </div>
            <!--以下操作可合并-->
            <div class="queren" v-if="
                item.managerId != null && item.applyStatus != '1' && item.applyStatus != '3' &&
                  item.confirmStatus == 0 &&
                  res.isTrue == 'true'
              " @click="lijiconfirm(item)">
              立即确认
            </div>
            <div class="liji" v-if="
                item.orderStatus == 0 &&
                  item.applyStatus != 1 &&
                  res.isTrue == 'true' && item.orderSource == 4
              " @click="fukuan2(item)">
              立即兑换
            </div>
            <div class="liji" v-if="
                item.orderStatus == 0 &&
                  item.applyStatus != 1 &&
                  res.isTrue == 'true' && item.orderSource != 4
              " @click="fukuan(item)">
              立即付款
            </div>
            <div class="liji" v-if="item.orderStatus != 10 && res.isTrue == 'true'&&item.orderSource!=4" @click="showCertList(item, res)">
              查看证书列表
            </div>
            <div class="shachu" v-if="item.orderStatus == 0 && res.isTrue == 'true'" @click="quxiao(item)">
              取消订单
            </div>
            <div class="liji1" v-if="item.orderStatus == 2 && res.isTrue == 'true'" @click="shouhuo(item)">
              确认收货
            </div>
            <div class="fapiaoshenqing" @click="applyInvoice(item)" v-if="
                ((item.orderStatus == 3 &&
                  (item.orderExt.needInv == 0 ||
                    item.orderExt.needInv == null)) ||
                  (item.orderStatus == 4 &&
                    (item.orderExt.needInv == 0 ||
                      item.orderExt.needInv == null))) &&
                  res.isTrue == 'true'
              ">
              申请发票
            </div>
            <div class="fapiaochakan" @click="showInvoice(item)" v-if="
                ((item.orderStatus == 3 && item.orderExt.needInv == 2) ||
                  (item.orderStatus == 4 && item.orderExt.needInv == 2)) &&
                  res.isTrue == 'true'
              ">
              查看发票
            </div>
            <div class="fapiaoshenqingzhong" v-if="
                ((item.orderStatus == 3 && item.orderExt.needInv == 1) ||
                  (item.orderStatus == 4 && item.orderExt.needInv == 1)) &&
                  res.isTrue == 'true'
              ">
              发票申请中
            </div>
          </div>
        </div>

        <!-- 分页 -->
      </div>
      <div class="fenye" v-if="order_list != ''">
        <el-pagination @current-change="pagechange" :current-page="page.current"
          style="text-align: right;margin-top: 20px" background :total="page.total" :page-size="page.size"
          layout="prev, pager, next, jumper, ->, total">
        </el-pagination>
      </div>

      <el-dialog title="物流" :visible.sync="dialogFormVisible">
        <div class="block">
          <el-timeline>
            <el-timeline-item v-for="(activity, index) in wuliu_list" :key="index" :timestamp="activity.time"
              :color="activity.ftime">
              {{ activity.context }}
            </el-timeline-item>
          </el-timeline>
        </div>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
        </div>
      </el-dialog>

      <el-dialog title="新增发票抬头" :visible.sync="dialogVisible">
        <el-form ref="form" :model="form" :rules="rules" label-width="80px">
          <el-form-item label-width="400px">
            <span slot="label"><span style="color: #f56c6c;font-size: 14px;">
                注意：发票抬头一经设置无法修改，若修改请联系管理员
              </span></span>
          </el-form-item>
          <el-form-item v-show="false" label="订单编号" label-width="100px" prop="orderId">
            <el-input v-model="form.orderId"></el-input>
          </el-form-item>
          <el-form-item label="发票类型" label-width="100px" prop="invType">
            <el-select v-model="form.invType" placeholder="发票类型">
              <el-option label="电子普通发票" value="0"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票内容" label-width="100px" prop="region">
            <el-select v-model="form.region" placeholder="发票内容">
              <el-option label="明细" value="mx"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="抬头类型" label-width="100px" prop="ivcTitleType">
            <el-select v-model="form.ivcTitleType" placeholder="抬头类型">
              <el-option label="个人" value="0"></el-option>
              <el-option label="企业" value="1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发票抬头" label-width="100px" prop="invTitle">
            <el-input v-model="form.invTitle"></el-input>
          </el-form-item>
          <el-form-item v-if="form.ivcTitleType == '1'" label="税号" label-width="100px" prop="registCode">
            <el-input v-model="form.registCode"></el-input>
          </el-form-item>

          <el-form-item v-if="form.ivcTitleType == '1'" label="开户银行" label-width="100px" prop="registBank">
            <el-input v-model="form.registBank"></el-input>
          </el-form-item>
          <el-form-item v-if="form.ivcTitleType == '1'" label="银行账号" label-width="100px" prop="bankAccount">
            <el-input v-model="form.bankAccount"></el-input>
          </el-form-item>
          <el-form-item v-if="form.ivcTitleType == '1'" label="企业地址" label-width="100px" prop="registAddress">
            <el-input v-model="form.registAddress"></el-input>
          </el-form-item>
          <el-form-item v-if="form.ivcTitleType == '1'" label="企业电话" label-width="100px" prop="registPhone">
            <el-input v-model="form.registPhone"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="submitForm('form')">确 定</el-button>
        </div>
      </el-dialog>

      <el-dialog title="对公打款" :visible.sync="dialogVisible1" width="40%" :before-close="handleClose">
        <el-form ref="formdata" :model="formdata" label-width="140px" :rules="rules">
          <el-form-item label="交易编号" prop="payRemittanceId">
            <el-input v-model="formdata.payRemittanceId"></el-input>
          </el-form-item>
          <el-form-item label="付款人姓名" prop="payerName">
            <el-input v-model="formdata.payerName"></el-input>
          </el-form-item>
          <el-form-item label="付款账号" prop="payerCardNumber">
            <el-input v-model="formdata.payerCardNumber"></el-input>
          </el-form-item>
          <el-form-item label="付款摘要" prop="paymentSummary">
            <el-input v-model="formdata.paymentSummary"></el-input>
          </el-form-item>
          <el-form-item label="请上传付款凭证：" prop="imageUrl">
            <el-upload class="avatar-uploader" :action="picurl" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload" list-type="picture-card">
              <Uimage v-if="formdata.imageUrl" :src="formdata.imageUrl" class="avatar" />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible1 = false">取 消</el-button>
          <el-button type="primary" @click="confirm('formdata')">确 定</el-button>
        </span>
      </el-dialog>

      <el-dialog title="对公打款" :visible.sync="dialogVisible2" width="40%" :before-close="handleClose">
        <el-form ref="formdata1" :model="formdata1" label-width="140px">
          <el-form-item label="交易编号">
            <el-input v-model="formdata1.payRemittanceId" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="付款人姓名">
            <el-input v-model="formdata1.payerName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="付款账号">
            <el-input v-model="formdata1.payerCardNumber" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="付款摘要">
            <el-input v-model="formdata1.paymentSummary" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="请上传付款凭证：">
            <Uimage :src="formdata1.imageUrl" class="avatar" />
          </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible2 = false">取 消</el-button>
        </span>
      </el-dialog>
      <cert_list ref="certList" />
    </div>
  </div>
</template>
<script>
  import {
    post,
    get
  } from '@/utils/request';
  import {
    setContextData,
    getContextData
  } from '../../../utils/session.js';
  import {
    confirmOrder
  } from '@/utils/pageUtils.js';
  import {
    submitOrderTemp
  } from '@/api/order.js';
  import {
    openPay
  } from '@/utils/pageUtils.js';
  import {
    baseURL
  } from '@/utils/request';
  import cert_list from './cert-list';
  export default {
    components: {
      cert_list
    },
    data() {
      return {
        order_list: [],
        isLoading: true,
        isLoading1: false,
        mes: '',
        tishi: '空空如也~',
        currentPage: 1,
        totalNum: 1,
        wuliu_list: [],
        dialogFormVisible: false,
        dialogVisible: false,
        page: {
          total: 1,
          size: 10,
          current: 1,
        },
        form: {
          orderId: '',
          invType: '',
          region: '',
          ivcTitleType: '',
          invTitle: '',
          registCode: '',
          registBank: '',
          bankAccount: '',
          registAddress: '',
          registPhone: '',
        },
        formdata: {
          payRemittanceId: '',
          payerName: '',
          payerCardNumber: '',
          paymentSummary: '',
          imageUrl: '',
        },
        formdata1: {
          payRemittanceId: '',
          payerName: '',
          payerCardNumber: '',
          paymentSummary: '',
          imageUrl: '',
        },
        picurl: baseURL + 'f/api/frontCommon/payVoucher',
        coverImg: '',
        orderId: '',
        imageUrl: '',
        rules: {
          invTitle: [{
            required: true,
            message: '请填写发票抬头',
            trigger: 'blur'
          }, ],
          registCode: [{
            required: true,
            message: '请填写税号',
            trigger: 'blur'
          }, ],
          payRemittanceId: [{
              required: true,
              message: '请输入交易编号',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 100,
              message: '长度在 1 到 100 个字符',
              trigger: 'blur',
            },
          ],
          payerName: [{
              required: true,
              message: '请输入付款人姓名',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 100,
              message: '长度在 1 到 100 个字符',
              trigger: 'blur',
            },
          ],
          payerCardNumber: [{
              required: true,
              message: '请输入付款账号',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 50,
              message: '长度在 1 到 50 个字符',
              trigger: 'blur',
            },
          ],
          paymentSummary: [{
              required: true,
              message: '请输入付款摘要',
              trigger: 'blur'
            },
            {
              min: 1,
              max: 100,
              message: '长度在 1 到 100 个字符',
              trigger: 'blur',
            },
          ],
          imageUrl: [{
            required: true,
            message: '请上传付款凭证',
            trigger: 'blur'
          }, ],
        },
        dialogVisible1: false,
        dialogVisible2: false,
      };
    },
    created() {
      this.currentPage = getContextData('currentPage') || 1;
      this.getorder(this.currentPage);
    },
    methods: {
      updateVoucher(e) {
        this.formdata.payRemittanceId = e.payBasic.payRemittanceId;
        this.formdata.payerName = e.payBasic.payerName;
        this.formdata.payerCardNumber = e.payBasic.payerCardNumber;
        this.formdata.paymentSummary = e.payBasic.paymentSummary;
        this.formdata.imageUrl = e.payBasic.paymentVoucher;
        this.orderId = e.payBasic.orderId;
        this.dialogVisible1 = true;
      },
      lookVoucher(e) {
        this.formdata1.payRemittanceId = e.payBasic.payRemittanceId;
        this.formdata1.payerName = e.payBasic.payerName;
        this.formdata1.payerCardNumber = e.payBasic.payerCardNumber;
        this.formdata1.paymentSummary = e.payBasic.paymentSummary;
        this.formdata1.imageUrl = e.payBasic.paymentVoucher;
        this.dialogVisible2 = true;
      },
      confirm(formdata) {
        this.$refs[formdata].validate((valid) => {
          if (valid) {
            post('api/orderbasic/updatePayBasic', {
              paymentVoucher: this.coverImg,
              orderId: this.orderId,
              paymentAmount: this.sumPrice,
              payRemittanceId: this.formdata.payRemittanceId,
              payerName: this.formdata.payerName,
              payerCardNumber: this.formdata.payerCardNumber,
              paymentSummary: this.formdata.paymentSummary,
            }).then((res) => {
              this.dialogVisible1 = false;
              this.getorder(this.currentPage);
            });
          } else {
            return false;
          }
        });
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then((_) => {
            done();
          })
          .catch((_) => {});
      },
      handleAvatarSuccess(res, file) {
        this.coverImg = res.fileName;
        this.formdata.imageUrl = res.url;
      },
      beforeAvatarUpload(file) {
        const isJPG =
          file.type === 'image/jpeg' ||
          'image/jpg' ||
          'image/webp' ||
          'image/png';
        const isLt2M = file.size / 1024 / 1024 < 10;

        if (!isJPG) {
          this.$message.error({
            message: '图片格式不正确!(只能包含jpg，png，webp，JPEG)',
          });
        }
        if (!isLt2M) {
          this.$message.error({
            message: '上传头像图片大小不能超过 10MB!',
          });
        }
        return isJPG && isLt2M;
      },
      xiangqing(storeId, spuId) {
        const {
          href
        } = this.$router.resolve({
          name: 'detail',
          params: {
            //店铺id
            storeId: storeId,
            //商品id
            spu: spuId,
          },
        });
        window.open(href, '_blank');
      },
      //店铺
      dianpu(e) {
        const {
          href
        } = this.$router.resolve({
          name: 'store_index',
          params: {
            storeId: e.storeId,
          },
        });
        window.open(href, '_blank');
      },
      //再次购买 重新购买
      buy(item, res) {
        // 根据批次号获取订单商品sku明细信息集合
         get('api/orderbasic/getOrderSkuListByBatchNumber?batchNumber='+res.batchNumber).then(
          (res) => {
            let orderTemp = [];
            res.data.data.forEach((req) => {
              orderTemp.push({
                skuId: req.skuId,
                num: req.skuCount,
                value: req.value,
                storeId: item.storeId,
                remark: 1,
              });
            })
            // 提交临时订单
            submitOrderTemp(orderTemp).then((res) => {
              confirmOrder();
            });
          })
      },
      //查看证书列表 order_sku_id
      showCertList(item, req) {
        //打开证书页面
        this.isLoading = false;
        this.$refs.certList.orderSkuId = req.orderSkuId;
        this.$refs.certList.orderNo = item.orderNo;
        this.$refs.certList.orderId = item.orderId;
        this.$refs.certList.initData();
        this.$refs.certList.dialogTableVisible = true;
        this.$refs.certList.loading = true;
      },
      // 查看证书
      showCert(item, req) {
        post('api/cert/createPDFCert', {
          orderId: req.orderId,
          orderNo: item.orderNo,
          spuId: req.spuId,
          spuName: req.spuName,
          skuId: req.skuId,
          skuName: req.skuName
        }).then((res) => {
          if (res.data.data.certUrl == '' || res.data.data.certUrl == null) {
            this.$message.warning({
              message: '证书不存在',
            });
          } else {
            // 跳转在线预览证书pdf页面
            const {
              href
            } = this.$router.resolve({
              name: 'CertPDF',
              params: {
                //店铺id
                certUrl: res.data.data.certUrl
              },
            });
            console.log(href)
            window.open(href, '_blank');
          }
        })
      },
      wuliu(e) {
        this.isLoading1 = true;
        get(
          'api/orderexpress/getorderexpress?com=' +
          e.orderExt.expressId +
          '&num=' +
          e.orderExt.expressNum +
          ''
        ).then((res) => {
          if (
            res.data.data.returnCode == undefined ||
            res.data.data.status == 200
          ) {
            res.data.data.data[0].ftime = '#0bbd87';
            this.wuliu_list = res.data.data.data;
            this.isLoading1 = false;
            this.dialogFormVisible = true;
          } else {
            this.isLoading1 = false;
            this.$message.warning({
              message: res.data.data.message,
            });
          }
        });
      },
      pagechange(p) {
        this.isLoading = true;
        this.page.current = p;
        setContextData('currentPage', this.currentPage);
        get(
          'api/orderbasic/getOrderByMemberId?pageNum=' + p + '&pageSize=10'
        ).then((res) => {
          this.isLoading = false;
          res.data.data.list.forEach((req) => {
            if (req.orderSource == 4) {
              req.orderSkuList[0].skuRealPrice = 0;
            }
            req.orderSkuList.forEach((q) => {
              q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
              if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
                if (q.value.indexOf('mol/mol') != -1) {
                  q.saleAttrCode.push({
                    attrName: '确认含量',
                    attrValue: q.value.replace(
                      'mol/mol',
                      'X10<sup>-6</sup>mol/mol'
                    ),
                  });
                } else {
                  q.saleAttrCode.push({
                    attrName: '确认含量',
                    attrValue: q.value,
                  });
                }
              }
              q.saleAttrCode.forEach((k, index) => {
                if (k.attrName == '浓度范围') {
                  q.saleAttrCode.splice(index, 1);
                }
              });
              Object.assign(q, {
                isTrue: 'false'
              });

            });
            for (let q = 0; q < req.orderSkuList.length; q++) {
              for (let j = q + 1; j < req.orderSkuList.length; j++) {
                if (req.orderSkuList[q].remark == '1' && req.orderSkuList[j].remark == '1') {
                  if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName && req.orderSkuList[q]
                    .storeId == req.orderSkuList[j].storeId && req.orderSkuList[q].prodCatId == req
                    .orderSkuList[j].prodCatId && req.orderSkuList[q].batchNumber == req.orderSkuList[j]
                    .batchNumber) {
                    for (
                      let p = 0; p < req.orderSkuList[j].saleAttrCode.length; p++
                    ) {
                      if (
                        req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                        req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                        req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                      ) {
                        req.orderSkuList[q].saleAttrCode.push(
                          req.orderSkuList[j].saleAttrCode[p]
                        );
                      }
                    }
                  }
                }
              }
            }
            req.orderSkuList[0].isTrue = 'true';
          });
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                  if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                    .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId && i
                    .orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          })
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                  if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                    .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId && i
                    .orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          })
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                  if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                    .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId && i
                    .orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          })
          this.order_list = res.data.data.list;
        });
      },
      getorder(e) {
        get(
          'api/orderbasic/getOrderByMemberId?pageNum=' + e + '&pageSize=10'
        ).then((res) => {
          this.isLoading = false;
          res.data.data.list.forEach((req) => {
            if (req.orderSource == 4) {
              req.orderSkuList[0].skuRealPrice = 0;
            }
            req.orderSkuList.forEach((q) => {
              q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
              if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
                if (q.value.indexOf('mol/mol') != -1) {
                  q.saleAttrCode.push({
                    attrName: '确认含量',
                    attrValue: q.value.replace(
                      'mol/mol',
                      'X10<sup>-6</sup>mol/mol'
                    ),
                  });
                } else {
                  q.saleAttrCode.push({
                    attrName: '确认含量',
                    attrValue: q.value,
                  });
                }
              }
              q.saleAttrCode.forEach((k, index) => {
                if (k.attrName == '浓度范围') {
                  q.saleAttrCode.splice(index, 1);
                }
              });
              Object.assign(q, {
                isTrue: 'false'
              });
            });

            for (let q = 0; q < req.orderSkuList.length; q++) {
              for (let j = q + 1; j < req.orderSkuList.length; j++) {
                if (req.orderSkuList[q].remark == '1' && req.orderSkuList[j].remark == '1') {
                  if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName && req.orderSkuList[q]
                    .storeId == req.orderSkuList[j].storeId && req.orderSkuList[q].prodCatId == req
                    .orderSkuList[j].prodCatId && req.orderSkuList[q].batchNumber == req.orderSkuList[j]
                    .batchNumber) {
                    for (
                      let p = 0; p < req.orderSkuList[j].saleAttrCode.length; p++
                    ) {
                      if (
                        req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                        req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                        req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                      ) {
                        req.orderSkuList[q].saleAttrCode.push(
                          req.orderSkuList[j].saleAttrCode[p]
                        );
                      }
                    }
                  }
                }
              }
            }
            req.orderSkuList[0].isTrue = 'true';
          });
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                  if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                    .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId && i
                    .orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          })
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                  if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                    .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId && i
                    .orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          })
          res.data.data.list.forEach((i) => {
            for (let q = 0; q < i.orderSkuList.length; q++) {
              for (let j = q + 1; j < i.orderSkuList.length; j++) {
                if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                  if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                    .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId && i
                    .orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                    i.orderSkuList.splice(j, 1);
                  }
                }
              }
            }
          })
          this.order_list = res.data.data.list;
          this.page.total = res.data.data.total;
        });
      },
      search() {
        this.isLoading = true;
        get('api/orderbasic/getOrderByMemberId?orderNo=' + this.mes).then(
          (res) => {
            this.isLoading = false;
            if (res.data.data.list == '') {
              this.tishi = '未查到相关订单~';
              res.data.data.list.forEach((req) => {
                if (req.orderSource == 4) {
                  req.orderSkuList[0].skuRealPrice = 0;
                }
              });
              this.order_list = res.data.data.list;
            } else {
              res.data.data.list.forEach((req) => {
                if (req.orderSource == 4) {
                  req.orderSkuList[0].skuRealPrice = 0;
                }
                req.orderSkuList.forEach((q) => {
                  q.saleAttrCode = eval('(' + q.saleAttrCode + ')');
                  if (q.value != '0mol/mol' && q.value != '0ppm' && q.value != null) {
                    if (q.value.indexOf('mol/mol') != -1) {
                      q.saleAttrCode.push({
                        attrName: '确认含量',
                        attrValue: q.value.replace(
                          'mol/mol',
                          'X10<sup>-6</sup>mol/mol'
                        ),
                      });
                    } else {
                      q.saleAttrCode.push({
                        attrName: '确认含量',
                        attrValue: q.value,
                      });
                    }
                  }
                  q.saleAttrCode.forEach((k, index) => {
                    if (k.attrName == '浓度范围') {
                      q.saleAttrCode.splice(index, 1);
                    }
                  });
                  Object.assign(q, {
                    isTrue: 'false'
                  });
                });
                for (let q = 0; q < req.orderSkuList.length; q++) {
                  for (let j = q + 1; j < req.orderSkuList.length; j++) {
                    if (req.orderSkuList[q].remark == '1' && req.orderSkuList[j].remark == '1') {
                      if (req.orderSkuList[q].skuName == req.orderSkuList[j].skuName && req.orderSkuList[q]
                        .storeId == req.orderSkuList[j].storeId && req.orderSkuList[q].prodCatId == req
                        .orderSkuList[j].prodCatId && req.orderSkuList[q].batchNumber == req.orderSkuList[j]
                        .batchNumber) {
                        for (
                          let p = 0; p < req.orderSkuList[j].saleAttrCode.length; p++
                        ) {
                          if (
                            req.orderSkuList[j].saleAttrCode[p].attrName == '多组分' ||
                            req.orderSkuList[j].saleAttrCode[p].attrName == '气体组分' ||
                            req.orderSkuList[j].saleAttrCode[p].attrName == '确认含量'
                          ) {
                            req.orderSkuList[q].saleAttrCode.push(
                              req.orderSkuList[j].saleAttrCode[p]
                            );
                          }
                        }
                      }
                    }
                  }
                }
                req.orderSkuList[0].isTrue = 'true';
              });
              res.data.data.list.forEach((i) => {
                for (let q = 0; q < i.orderSkuList.length; q++) {
                  for (let j = q + 1; j < i.orderSkuList.length; j++) {
                    if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                      if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                        .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                        i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                        i.orderSkuList.splice(j, 1);
                      }
                    }
                  }
                }
              })
              res.data.data.list.forEach((i) => {
                for (let q = 0; q < i.orderSkuList.length; q++) {
                  for (let j = q + 1; j < i.orderSkuList.length; j++) {
                    if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                      if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                        .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                        i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                        i.orderSkuList.splice(j, 1);
                      }
                    }
                  }
                }
              })
              res.data.data.list.forEach((i) => {
                for (let q = 0; q < i.orderSkuList.length; q++) {
                  for (let j = q + 1; j < i.orderSkuList.length; j++) {
                    if (i.orderSkuList[q].remark == '1' && i.orderSkuList[j].remark == '1') {
                      if (i.orderSkuList[q].skuName == i.orderSkuList[j].skuName && i.orderSkuList[q].storeId == i
                        .orderSkuList[j].storeId && i.orderSkuList[q].prodCatId == i.orderSkuList[j].prodCatId &&
                        i.orderSkuList[q].batchNumber == i.orderSkuList[j].batchNumber) {
                        i.orderSkuList.splice(j, 1);
                      }
                    }
                  }
                }
              })
              this.order_list = res.data.data.list;
              this.page.total = res.data.data.total;
            }
          }
        );
      },
      //订单详情
      ding_detail(e) {
        this.$router.push({
          path: '/detail_dingdan',
          query: {
            orderId: e.orderId,
          },
        });
      },
      // 立即确认
      lijiconfirm(e) {
        this.$confirm('您是否同意此条代购订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          get(
            'api/orderbasic/updateOrderConfirmStatus?orderId=' + e.orderId
          ).then((res) => {
            this.getorder(this.currentPage);
            get(
              'api/syncData/orderToErp?orderId=' + e.orderId
            ).then((res) => {
              post('api/wxMsgPush/sendOrderConfirmStatusWxMsg?orderId='+e.orderId+'').then((res) => {
              });
            });
          });
        });
      },
      //付款
      fukuan(e) {
        //openPay(e);
        this.$router.push({
          name: 'Zhuanzhang',
          params: {
            price: e.orderAmount,
          },
          query: {
            orderId: e.orderId,
            isBuy: false
          },
        });
      },
      fukuan2(e) {
        this.$router.push({
            name: 'Zhuanzhang',
            params: {
              price: this.price,
            },
            query: {
              orderId: e.orderNo,
              istrue: '1',
              isBuy:false,
              jifen:1
            },
          });
      },
      tuikuan(e) {
        this.$router.push({
          name: 'Tuihuosq',
          params: {
            skuId: e.skuId,
            orderId: e.orderId,
          },
        });
      },
      //确认收货
      shouhuo(e) {
        get('api/orderbasic/updateorderReceiv?orderId=' + e.orderId).then(
          (res) => {
            this.getorder(this.currentPage);
            // 验证订单是否已收货()
            // this.checkOrderStatus(e.orderId, 0);
          }
        );
      },
      //取消订单
      quxiao(e) {
        this.$confirm('是否取消此条订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          get('api/orderbasic/updateorder?orderId=' + e.orderId).then((res) => {
            this.getorder(this.currentPage);
          });
        });
      },
      pingjia(e) {
        this.$router.push({
          name: 'Pingjia',
          params: {
            skuId: e.skuId,
            orderId: e.orderId,
            batchNumber: e.batchNumber
          },
        });
      },
      //删除订单
      del(e) {
        this.$confirm('是否删除此条订单?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          this.isLoading = true;
          get('api/orderbasic/deleteOrderBasicById?orderId=' + e.orderId).then(
            (res) => {
              this.$message.success({
                message: '删除成功',
              });
              this.getorder(this.currentPage);
            }
          );
        });
      },
      // 申请发票
      applyInvoice(e) {
        // 1.验证订单发票开具是否已超时(超过30天不可开具发票，反之即可)
        get(
          'api/invoice/checkIsPrintInvoiceByOrderId?orderId=' +
          e.orderId.toString()
        ).then((res) => {
          // 订单不超过30天，执行开具发票
          if (res.data.data == false) {
            this.checkInvoice(e);
          } else {
            this.$message.warning({
              message: '该订单发票申请已超时，不可开具！',
            });
          }
        });
      },
      // 验证是否存在会员发票抬头
      checkInvoice(e) {
        // 2.验证是否存在会员发票抬头(若存在则直接开具发票，反之需执行新增发票抬头)
        get('api/invoice/checkIsInvoiceTitleByMemberId').then((res) => {
          // 新增发票抬头
          if (res.data.msg == '' || res.data.msg == null) {
            this.form.orderId = e.orderId;
            this.form.invType = '0';
            this.form.region = 'mx';
            this.form.ivcTitleType = '0';
            this.form.invTitle = '';
            this.form.registCode = '';
            this.form.registBank = '';
            this.form.bankAccount = '';
            this.form.registAddress = '';
            this.form.registPhone = '';
            this.dialogVisible = true;
          } else {
            // 根据订单状态是否确认收货前申请开票
            this.checkOrderStatus(e.orderId, res.data.msg);
          }
        });
      },
      // 保存发票抬头
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            post('api/invoice/saveInvoiceTitle', {
              invType: this.form.invType,
              region: this.form.region,
              ivcTitleType: this.form.ivcTitleType,
              invTitle: this.form.invTitle,
              companyName: this.form.invTitle,
              registCode: this.form.registCode,
              registBank: this.form.registBank,
              bankAccount: this.form.bankAccount,
              registAddress: this.form.registAddress,
              registPhone: this.form.registPhone,
            }).then((res) => {
              this.$message.success({
                message: '新增发票抬头成功',
              });
              this.dialogVisible = false;
              // 根据订单状态是否确认收货前申请开票
              this.checkOrderStatus(this.form.orderId, res.data.data);
            });
          } else {
            return false;
          }
        });
      },
      // 打印发票
      printInvoice(orderId, invId) {
        post('api/invoice/printInvoice', {
          invId: invId, // 发票抬头
          orderId: orderId, // 订单id
          invoiceType: '0', // 发票类型
        }).then((res) => {
          this.isLoading = false;
        });
      },
      // 验证订单是否已收货
      checkOrderStatus(orderId, invId) {
        get(
          'api/invoice/checkOrderStatusByOrderId?orderId=' +
          orderId +
          '&invId=' +
          invId
        ).then((res) => {
          // 订单没有收货，需确认收货才会开具发票
          if (res.data.data == false) {
            // 刷新订单列表(显示发票申请中)
            this.getorder(this.currentPage);
            this.$message.success({
              message: '发票申请提交成功',
            });
          }
        });
      },
      // 查看发票
      showInvoice(item) {
        let src = item.orderInv.localUrl;
        if (src.indexOf('/') == 0 || src.indexOf('\\')) {
          src = src.substring(1);
        }
        window.open(baseURL + src, '_blank');
      },
    },
  };
</script>
<style lang="scss" scoped>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 78px;
    line-height: 78px;
    text-align: center;
  }

  .avatar {
    width: 400px;
    height: 200px;
  }

  .company-info-wrapper {
    background-color: #f5f5f5;
  }

  .tishi {
    width: 90%;
    text-align: center;
    margin-top: 150px;
    font-size: 24px;
    color: rgb(139, 135, 135);
  }

  .right {
    width: 1006px;
    padding-top: 34px;
    padding-left: 27px;
    background: #fff;

    .spen {
      font-size: 26px;
      font-weight: 600;
      color: #616161;
    }

    .menu {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .daohang {
        font-size: 15px;
        color: #272727;
        margin-right: 25px;
        height: 30px;

        .ul {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0;
          width: 400px;

          li {
            list-style: none;
            cursor: pointer;

            &.router-link-exact-active.router-link-active {
              color: #3661fe;
            }
          }
        }
      }

      .search {
        margin-right: 150px;
        display: flex;

        input {
          border: 0;
          outline: none;
          border: 1px solid #a7a7a7;
          height: 37px;
          padding-left: 10px;
        }

        .fangdajing {
          width: 45px;
          height: 40px;
          border: 1px solid #a7a7a7;
          border-left: none;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }

    .fenye {
      margin-top: 50px;
      margin-bottom: 20px;
    }

    .top {
      width: 100%;
      height: 42px;
      line-height: 42px;
      background: #f5f5f5;
      display: flex;
      margin-top: 30px;

      .biaoti {
        font-size: 16px;
        color: #888;
        margin-left: 20px;

        .danhao {
          color: #666;
          font-weight: 800;
        }

        img {
          width: 23px;
          height: 23px;
          margin-left: 10px;
        }
      }

      .dianpu {
        cursor: pointer;
      }

      .mar {
        margin-left: 40px;
      }

      .kefu {
        display: flex;
        align-items: center;
      }
    }

    .bottom {
      padding-bottom: 20px;
      width: 100%;
      border-bottom: 1px solid #f5f5f5;
      display: flex;

      .pic {
        width: 180px;
        height: 136px;
        margin-top: 40px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .title1 {
        height: 100%;
        border-right: 1px solid #eaeaea;
        padding-right: 10px;
        width: 345px;

        .title {
          padding-top: 34px;
          display: flex;
          justify-content: space-between;

          .miaoshu {
            color: #333;
            width: 190px;
            margin-left: 10px;
            height: 40px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            cursor: pointer;
          }

          .shuliang {
            margin-left: 10px;
          }
        }

        .xiangsi {
          font-size: 14px;
          color: #9a9a9a;
          margin-top: 10px;
        }

        .shuxing {
          margin-left: 10px;
          font-size: 12px;
          color: #9a9a9a;
          /* margin-top: 10px; */
          display: flex;
        }
      }

      .tousu {
        width: 102px;
        border-right: 1px solid #eaeaea;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        color: #333;
      }

      .price {
        width: 158px;
        border-right: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .jiage {
          font-size: 14px;
          color: #333;
        }

        .yunfei {
          color: #a0a0a0;
          font-size: 12px;
        }

        .fangshi {
          color: #a0a0a0;
          font-size: 12px;
        }
      }

      .zhuangtai {
        width: 130px;
        border-right: 1px solid #eaeaea;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #333;

        .xiangqing {
          margin-top: 5px;
          cursor: pointer;
        }

        .wuliu {
          color: #dc7413;
          margin-top: 5px;
          cursor: pointer;
        }

        .quxiao {
          color: #a0a0a0;
          margin-top: 5px;
        }
      }

      .pay {
        width: 136px;
        border-right: 1px solid #eaeaea;
        font-size: 12px;
        color: #333;
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .liji {
          color: #fff;
          background: #1850ff;
          padding: 5px 10px;
          width: 85px;
          border-radius: 5px;
          margin: 0 auto;
          cursor: pointer;
          margin-top: 10px;
        }

        .liji1 {
          color: #fff;
          background: #ff902a;
          padding: 5px 10px;
          width: 85px;
          border-radius: 5px;
          margin: 0 auto;
          margin-top: 10px;
          cursor: pointer;
        }

        .daifu {
          cursor: pointer;
          margin-top: 5px;
        }

        .shachu {
          color: #fff;
          background: #ff902a;
          padding: 5px 10px;
          width: 85px;
          border-radius: 5px;
          margin: 0 auto;
          margin-top: 10px;
          cursor: pointer;
        }

        .quxiao {
          cursor: pointer;
          margin-top: 5px;
        }

        .queren {
          color: #fff;
          background: #42b983;
          padding: 5px 10px;
          width: 85px;
          border-radius: 5px;
          margin: 0 auto;
          cursor: pointer;
          margin-top: 10px;
        }

        .fapiaoshenqing {
          color: #fff;
          background: #42b983;
          padding: 5px 10px;
          width: 85px;
          border-radius: 5px;
          margin: 0 auto;
          cursor: pointer;
          margin-top: 10px;
        }

        .fapiaoshenqingzhong {
          color: #fff;
          background: #ff902a;
          padding: 5px 10px;
          width: 85px;
          border-radius: 5px;
          margin: 0 auto;
          margin-top: 10px;
          cursor: pointer;
        }

        .fapiaochakan {
          color: #fff;
          background: #1850ff;
          padding: 5px 10px;
          width: 85px;
          border-radius: 5px;
          margin: 0 auto;
          cursor: pointer;
          margin-top: 10px;
        }

        .goods-xinghao {
          width: 180px;
          padding-top: 15px;
          margin-left: 20px;

          .goods-xinghao-list {
            display: flex;
            margin-top: 5px;
            color: #9c9c9c;

            &-r {
              margin-left: 5px;
            }
          }
        }
      }
    }
  }
</style>